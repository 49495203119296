"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ERROR_LANGUAGES_UNKNOWN = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var LANGUAGES_QUERY = "query getLanguages ($offset: Int, $limit: Int, $search: String) {\n\tlanguages (offset: $offset, limit: $limit, search: $search) {\n\t\ttotalCount\n\t\tnodes {\n\t\t\tid\n\t\t\tname\n\t\t\ttranslationFonts\n\t\t\tgfxTranslationFonts\n\t\t\tisoCode\n\t\t\tdcpCode\n\t\t\tcode\n\t\t}\n\t}\n}";
var LANGUAGES_QUERY_SHORT = "query getLanguages ($limit: Int, $search: String) {\n\tlanguages (limit: $limit, search: $search) {\n\t\ttotalCount\n\t\tnodes {\n\t\t\tid\n\t\t\tvalue: code\n\t\t\tlabel: name\n\t\t}\n\t}\n}";
var LANGUAGES_QUERY_SHORT_ORIGINAL = "query getLanguages ($limit: Int, $search: String) {\n\tlanguages (limit: $limit, search: $search) {\n\t\ttotalCount\n\t\tnodes {\n\t\t\tcode\n\t\t\tname\n\t\t}\n\t}\n}";
var ERROR_LANGUAGES_UNKNOWN = 'error.language.unknown';
exports.ERROR_LANGUAGES_UNKNOWN = ERROR_LANGUAGES_UNKNOWN;
var LanguageService = {
  getAllLanguages(params) {
    if (params) {
      return _apiService.default.graphql.request(LANGUAGES_QUERY, params).then(data => data.languages);
    }

    return _apiService.default.client.get('/languages', {
      headers: {
        'X-Range': 'resources=0-200'
      }
    }).then(res => {
      return res.data.map(lang => {
        return _objectSpread(_objectSpread({}, lang), {}, {
          font: this.font
        });
      });
    });
  },

  getAllLanguagesShort(params) {
    if (params !== null && params !== void 0 && params.original) {
      return _apiService.default.graphql.request(LANGUAGES_QUERY_SHORT_ORIGINAL, params).then(data => data.languages.nodes);
    }

    return _apiService.default.graphql.request(LANGUAGES_QUERY_SHORT, params).then(data => data.languages);
  },

  postLanguage(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.post("/languages", data).then(resolve).catch(err => {
        var _err$response, _err$response$data, _err$response$data$er, _err$response2;

        reject({
          code: ERROR_LANGUAGES_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : (_err$response$data$er = _err$response$data.error) === null || _err$response$data$er === void 0 ? void 0 : _err$response$data$er.message) || 'Failed to create a new language',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) || err
        });
      });
    });
  },

  patchLanguage(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/languages/".concat(data.id), data).then(resolve).catch(err => {
        var _err$response3, _err$response3$data, _err$response3$data$e, _err$response4;

        reject({
          code: ERROR_LANGUAGES_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : (_err$response3$data = _err$response3.data) === null || _err$response3$data === void 0 ? void 0 : (_err$response3$data$e = _err$response3$data.error) === null || _err$response3$data$e === void 0 ? void 0 : _err$response3$data$e.message) || 'Failed to update this language: This language has already been used, a migration script is needed to perform such changes',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) || err
        });
      });
    });
  },

  deleteLanguage(_ref) {
    var {
      id
    } = _ref;
    return new Promise((resolve, reject) => {
      _apiService.default.client.delete("/languages/".concat(id)).then(resolve).catch(err => {
        var _err$response5;

        reject({
          code: ERROR_LANGUAGES_UNKNOWN,
          message: (0, _lodash2.default)(err, 'response.data.message', 'An error has occurred while trying to delete this language, Please try again later'),
          innerError: (err === null || err === void 0 ? void 0 : (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.data) || err
        });
      });
    });
  },

  font(studioId) {
    var defaultFont = {
      face: 'Arial',
      size: 70
    };
    var font = _lodash.default.find(this.translationFonts, {
      studio: studioId
    }) || _lodash.default.find(this.translationFonts, studio => {
      return !!studio;
    }) || defaultFont;
    font = _lodash.default.cloneDeep(font);
    delete font.studio;
    return font;
  },

  isGfxAvailable(languageCode) {
    var unavailableLanguages = ['ARA-PFR', 'EST-RUS', 'PFR-FLE', 'GER-PFR', 'LAT-RUS'];
    return !unavailableLanguages.includes(languageCode);
  }

};
var _default = LanguageService;
exports.default = _default;